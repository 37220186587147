<!--
 * @Author: wyq
 * @Date: 2021-06-03 17:12:59
 * @LastEditTime: 2021-06-08 16:32:37
 * @LastEditors: wyq
 * @Description: 
 * @FilePath: \frontEnd\src\components\btnI18n.vue
-->
<template>
  <el-dropdown @command="handleLang">
    <span class="el-dropdown-lang">
      <i class="icon-lang">
        <svg
          t="1622688712467"
          class="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="1604"
          width="14"
          height="14"
        >
          <path
            d="M512 85.333333C277.333333 85.333333 85.333333 277.333333 85.333333 512s192 426.666667 426.666667 426.666667c234.666667 0 426.666667-192 426.666667-426.666667S746.666667 85.333333 512 85.333333zM806.4 341.333333 682.666667 341.333333c-12.8-55.466667-34.133333-102.4-59.733333-153.6C699.733333 217.6 768 268.8 806.4 341.333333zM512 170.666667c34.133333 51.2 64 106.666667 81.066667 170.666667l-162.133333 0C448 281.6 477.866667 221.866667 512 170.666667zM183.466667 597.333333C174.933333 571.733333 170.666667 541.866667 170.666667 512s4.266667-59.733333 12.8-85.333333l145.066667 0c-4.266667 29.866667-4.266667 55.466667-4.266667 85.333333s4.266667 55.466667 4.266667 85.333333L183.466667 597.333333zM217.6 682.666667 341.333333 682.666667c12.8 55.466667 34.133333 102.4 59.733333 153.6C324.266667 806.4 256 755.2 217.6 682.666667zM341.333333 341.333333 217.6 341.333333c42.666667-72.533333 106.666667-123.733333 183.466667-153.6C375.466667 238.933333 354.133333 285.866667 341.333333 341.333333zM512 853.333333c-34.133333-51.2-64-106.666667-81.066667-170.666667l162.133333 0C576 742.4 546.133333 802.133333 512 853.333333zM610.133333 597.333333 413.866667 597.333333c-4.266667-29.866667-8.533333-55.466667-8.533333-85.333333s4.266667-55.466667 8.533333-85.333333l200.533333 0c4.266667 29.866667 8.533333 55.466667 8.533333 85.333333S614.4 567.466667 610.133333 597.333333zM622.933333 836.266667c25.6-46.933333 46.933333-98.133333 59.733333-153.6l123.733333 0C768 755.2 699.733333 806.4 622.933333 836.266667zM699.733333 597.333333c4.266667-29.866667 4.266667-55.466667 4.266667-85.333333s-4.266667-55.466667-4.266667-85.333333l145.066667 0c8.533333 25.6 12.8 55.466667 12.8 85.333333s-4.266667 59.733333-12.8 85.333333L699.733333 597.333333z"
            p-id="1605"
            fill="#8590a6"
          />
        </svg>
      </i>
      {{lang}}
      <i class="el-icon-arrow-down"></i>
    </span>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item command="zh-CN">简体中文</el-dropdown-item>
      <el-dropdown-item command="zh-TW">繁体中文</el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>
<script>
export default {
  computed: {
    lang() {
      if (this.$i18n.locale == 'zh-CN') {
        return '简体'
      } else {
        return '繁体'
      }
    },
  },
  methods: {
    // 简繁切换
    handleLang(val) {
      if (this.$i18n.locale != val) {
        this.$i18n.locale = val
        this.$cookies.set('lang', val)
        if (this.$parent.$parent.init) {
          this.$parent.$parent.init()
        }
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.el-dropdown-lang {
  color: #8590a6;
  font-size: 12px;
  cursor: pointer;
  vertical-align: top;
  .icon-lang {
    position: relative;
    top: 2px;
  }
}
</style>