// 导出页面为PDF格式
import html2Canvas from 'html2canvas'
import JsPDF from 'jspdf'
// 将导出方法挂载到全局
export default {
  install(Vue, options) {
    Vue.prototype.getPdfByWeb = function(title) {
    // #pdfDom是要保存的页面元素
      return html2Canvas(document.querySelector('#pdfDom'), {
        allowTaint: true,
        taintTest: false,
        useCORS: true,
        async: true,
        scale: '2',
        dpi: '192'
      }).then(function(canvas) {
        const contentWidth = canvas.width
        const contentHeight = canvas.height
        // 一页pdf显示html页面生成的canvas高度;
        const pageHeight = Math.ceil(contentWidth / 595.28 * 841.89)
        // 未生成pdf的html页面高度
        let leftHeight = contentHeight
        // pdf页面偏移
        let position = 0
        // a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
        const imgWidth = 595.28
        const imgHeight = 595.28 / contentWidth * contentHeight
        // console.log(canvas)
        const pageData = canvas.toDataURL('image/jpeg/png', 1)
        const PDF = new JsPDF('', 'pt', 'a4', true)
        // console.log(PDF)
        if (leftHeight < pageHeight) {
          PDF.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight)
        } else {
          while (leftHeight > 0) {
            PDF.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
            leftHeight -= pageHeight
            position -= 841.89
            if (leftHeight > 0) {
              PDF.addPage()
            }
          }
        }
        PDF.save(title + '.pdf') //保存PDF
        return PDF.output('datauristring')
      })
    }
  }
}