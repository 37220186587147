/*
 * @Author: wyq
 * @Date: 2021-05-17 14:57:08
 * @LastEditTime: 2021-06-04 09:58:13
 * @LastEditors: wyq
 * @Description: 
 * @FilePath: \frontEnd\src\main.js
 */
document.title = '海经院数据库'
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Element from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
// 滚动条
import vuescroll from "vuescroll";//引入vuescroll
import "vuescroll/dist/vuescroll.css";//引入vuescroll样式

import service from './http/service'
import Cookies from 'js-cookie'

import htmlPdf from './components/buildPdf/pdf.js';

import publicFooter from './components/publicFooter'
import publicHeader from './components/publicHeader'
import backTop from './components/backTop'

// 国际化
import VueI18n from 'vue-i18n'
import dictionaries from './locale'
Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: Cookies.get('lang') || 'zh-CN',    // 语言标识, 通过切换locale的值来实现语言切换,this.$i18n.locale 
  messages: dictionaries
})
Vue.use(Element, {
  i18n: (key, value) => i18n.t(key, value)
})
Cookies.get('name')

Vue.use(htmlPdf);
Vue.use(vuescroll);

Vue.prototype.$service = service;
Vue.prototype.$cookies = Cookies;

//线上地址
Vue.prototype.baseUrl = "/v1";

Vue.config.productionTip = false;

// 配置全局filters
import * as filters from './utils/filters.js'
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

Vue.component("publicFooter", publicFooter);
Vue.component("publicHeader", publicHeader);
Vue.component("backTop", backTop);

new Vue({
  router,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
