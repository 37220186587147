/*
 * @Author: wyq
 * @Date: 2021-06-03 10:21:55
 * @LastEditTime: 2021-06-04 09:46:40
 * @LastEditors: wyq
 * @Description:
 * @FilePath: \frontEnd\src\locale\index.js
 */

import cnLocale from 'element-ui/lib/locale/lang/zh-CN'
import twLocale from 'element-ui/lib/locale/lang/zh-TW'
import zhcn from './zh-CN'
import zhtw from './zh-TW'
const dictionaries = {
  'zh-CN': {
    ...zhcn,
    ...cnLocale
  },
  'zh-TW': {
    ...zhtw,
    ...twLocale
  }
}
export default dictionaries