/*
 * @Author: wyq
 * @Date: 2021-06-03 09:41:27
 * @LastEditTime: 2021-06-24 14:36:13
 * @LastEditors: wyq
 * @Description:
 * @FilePath: \frontEnd\src\locale\zh-CN.js
 */
export default {
  // 首页
  '中国台湾地区行业手册': '中国台湾地区行业手册',
  '中国台湾地区行业手册的编订原则系参照联合国《国际行业分类标准》（International Standard Industrial Classification of All Economic Activites，简称ISIC）架构，并在其基础上将行业进一步划分为5级，总共囊括19大类、88中类、247小类、517细类、1598子类。分类中每一层级的所有类别都是非此即彼、相互排斥的。行业标准分类的归类原则建立在生产导向的概念上，即以产品、服务及生产要素投入、生产工艺及技术、产出特点及产出的用途等维度进行行业划分。在细类层级的划分上主要考量生产过程中的相似性，即将生产投入、生产程序及生产技术相似者划归为同一类别，有时也会考量产出的特性及用途；在中类、小类的分类层级中，生产程序及生产技术的重要性则相对较低': '中国台湾地区行业手册的编订原则系参照联合国《国际行业分类标准》（International Standard Industrial Classification of All Economic Activites，简称ISIC）架构，并在其基础上将行业进一步划分为5级，总共囊括19大类、88中类、247小类、517细类、1598子类。分类中每一层级的所有类别都是非此即彼、相互排斥的。行业标准分类的归类原则建立在生产导向的概念上，即以产品、服务及生产要素投入、生产工艺及技术、产出特点及产出的用途等维度进行行业划分。在细类层级的划分上主要考量生产过程中的相似性，即将生产投入、生产程序及生产技术相似者划归为同一类别，有时也会考量产出的特性及用途；在中类、小类的分类层级中，生产程序及生产技术的重要性则相对较低',
  '行业分类': '行业分类',
  '农、林、渔、牧业': '农、林、渔、牧业',
  '矿业及土石采取业': '矿业及土石采取业',
  '制造业': '制造业',
  '电力及燃气供应业': '电力及燃气供应业',
  '用水供应及污染整治业': '用水供应及污染整治业',
  '营建工程业': '营建工程业',
  '批发及零售业': '批发及零售业',
  '运输及仓储业': '运输及仓储业',
  '住宿及餐饮业': '住宿及餐饮业',
  '出版、影音制作、传播及资通信服务业': '出版、影音制作、传播及资通信服务业',
  '金融及保险业': '金融及保险业',
  '不动产业': '不动产业',
  '专业、科学及技术服务业': '专业、科学及技术服务业',
  '支持服务业': '支持服务业',
  '公共行政及国防&强制性社会安全': '公共行政及国防&强制性社会安全',
  '教育业': '教育业',
  '医疗保健及社会工作服务业': '医疗保健及社会工作服务业',
  '艺术、娱乐及休闲服务业': '艺术、娱乐及休闲服务业',
  '其他服务业': '其他服务业',
  // 头部组件
  '首页': '首页',
  '台企数据库': '台企数据库',
  '专精特新': '专精特新',
  '银发友善食品': '银发友善食品',
  '银发友善食品(Eatender)是由中国台湾地区财团法人食品工业发展研究所的银发友善餐食研究团队评选出的对银发族最具友善性的体贴食品。现银发友善食品入选产品已累计达到282项，来自97家台湾公司。所有获选的银发友善食品都必须符合以下特征：使用中国台湾地区的在地农产食材、符合食品安全卫生规范；在中国台湾地区加工制造。': '银发友善食品(Eatender)是由中国台湾地区财团法人食品工业发展研究所的银发友善餐食研究团队评选出的对银发族最具友善性的体贴食品。现银发友善食品入选产品已累计达到282项，来自97家台湾公司。所有获选的银发友善食品都必须符合以下特征：使用中国台湾地区的在地农产食材、符合食品安全卫生规范；在中国台湾地区加工制造。',
  '进入银发友善食品': '进入银发友善食品',
  '请输入公司名称或唯一编号': '请输入公司名称或唯一编号',
  '浏览记录': '浏览记录',
  // body组件
  '请选择行业分类': '请选择行业分类',
  // 尾部组件
  '版权归海峡经济研究院所有': '版权归海峡经济研究院所有',
  '备案号': '备案号',
  '闽ICP备2021008848号': '闽ICP备2021008848号',

  // 检索栏
  "全部结果": "全部结果",
  "地区": "地区",
  "资本规模": "资本规模",
  "标签": "标签",
  "万": "万",
  "亿": "亿",
  "行业分类": "行业分类",
  "公司类型": "公司类型",
  "相关标签": "相关标签",
  "上柜公司": "上柜公司",
  "兴柜公司": "兴柜公司",
  "公开发行公司": "公开发行公司",
  "非公开发行": "非公开发行",
  // 搜索页
  "家企业": "家企业",
  "为您优先匹配前5000家企业": "为您优先匹配前5000家企业",
  "资本额降序": "资本额降序",
  "资本额升序": "资本额升序",
  "默认排序": "默认排序",
  // 详情
  "统一编号": "统一编号",
  "登记现状": "登记现状",
  "电话": "电话",
  "电子邮件": "电子邮件",
  "资本总额(新台币)": "资本总额(新台币)",
  "公司官网": "公司官网",
  "营业项目": "营业项目",
  "产品信息": "产品信息",
  "暂时没有相关数据": "暂时没有相关数据",

  "传真": "传真",
  "代表人姓名": "代表人姓名",
  "公司状况": "公司状况",
  "核准建设日期": "核准建设日期",
  "实收资本(新台币)": "实收资本(新台币)",
  "公司简称": "公司简称",
  "股票代号": "股票代号",
  "董事长": "董事长",
  "总经理": "总经理",
  "发言人": "发言人",
  "产业类别": "产业类别",
  "主营业务": "主营业务",
  "投资联络": "投资联络",
  "联络人姓名": "联络人姓名",
  "联络人职称": "联络人职称",
  "联络人电话": "联络人电话",
  "联络人邮箱": "联络人邮箱",
  "奖补信息": "奖补信息",
  "获得由中国台湾地区": "获得由中国台湾地区",
  "颁发的": "颁发的",
  "获奖项目": "获奖项目",
  "营收资讯": "营收资讯",
  "单位：新台币千元": "单位：新台币千元",
  "本年至今累计营收": "本年至今累计营收",
  "去年同期累计营收": "去年同期累计营收",
  "去年同期月营收": "去年同期月营收",
  "累计营收成长率": "累计营收成长率",
  "财务资讯": "财务资讯",
  "期别": "期别",
  "简明资产负债": "简明资产负债",
  "简明综合损益表": "简明综合损益表",
  "简明现金流量表": "简明现金流量表",
  "资产总计": "资产总计",
  "负债总计": "负债总计",
  "权益总计": "权益总计",
  "每股净值(元)": "每股净值(元)",
  "营业收入": "营业收入",
  "营业利益": "营业利益",
  "税前净利": "税前净利",
  "基本每股盈余": "基本每股盈余",
  "营业活动之净现金流入": "营业活动之净现金流入",
  "投资活动之净现金流入": "投资活动之净现金流入",
  "筹资活动之净现金流入": "筹资活动之净现金流入",

  // 产品
  "产品名称": "产品名称",
  "包装": "包装",
  "储藏方式": "储藏方式",
  "产品类别": "产品类别",
  "获奖内容": "获奖内容",
  "参考售价": "参考售价",
  "销售通路": "销售通路",
  "产品介绍": "产品介绍",

  // 收藏
  "添加文件夹": "添加文件夹",
  "编辑": "编辑",
  "删除": "删除",
  "请输入内容": "请输入内容",

  // 浏览历史
  "为您找到": "为您找到",
  "条相关数据": "条相关数据",

  // 登录页
  "欢迎，请登录": "欢迎，请登录",
  "请输入账户": "请输入账户",
  "请输入密码": "请输入密码",
  "登录": "登录",

  "置顶": "置顶",

  "添加收藏夹": "添加收藏夹",
  "收藏夹名称": "收藏夹名称",
  "请输入名称": "请输入名称",
  "确认添加": "确认添加",
  "失败": "失败",
  "收藏夹名称不能为空": "收藏夹名称不能为空",
  "收藏夹编辑": "收藏夹编辑",
  "确认修改": "确认修改",
  "删除确认": "删除确认",
  "确认删除该分组吗": "确认删除该分组吗",
  "分组": "分组",
  "创建": "创建",
  "确认": "确认",
  "创建成功": "创建成功"
}
