<!--
 * @Author: wyq
 * @Date: 2021-06-01 14:32:29
 * @LastEditTime: 2021-06-24 14:36:40
 * @LastEditors: wyq
 * @Description: 
 * @FilePath: \frontEnd\src\components\publicFooter.vue
-->
<template>
  <div class="public-footer">
    <!-- <div class="public-footer-content">
      <div class="list">
        <p>快捷入口</p>
        <ul class="btn-group">
          <li>回到首页</li>
          <li>行业分类</li>
          <li>我的收藏</li>
          <li>浏览记录</li>
        </ul>
      </div>
      <div class="list">
        <p>联系我们</p>
        <ul>
          <li>电话：0591-12345678</li>
          <li>传真：0591-12345678</li>
          <li>邮箱：hjy@163.com</li>
        </ul>
      </div>
      <div class="qrcode">
        <img src="../assets/images/qrcode.png" />
        <p>微信</p>
      </div>
      <div class="qrcode">
        <img src="../assets/images/qrcode.png" />
        <p>QQ</p>
      </div>
    </div>-->
    <div class="record-code">
      {{$t('版权归海峡经济研究院所有')}}
      {{$t('备案号')}}：
      {{$t('闽ICP备2021008848号')}}
    </div>
  </div>
</template>
<script>
export default {
  name: 'publicFooter',
}
</script>
<style lang="scss" scoped>
.public-footer {
  // height: 313px;
  background-color: #333333;
  color: #fff;
  text-align: center;
  &-content {
    display: flex;
    justify-content: center;
    .list {
      margin: 64px 50px 46px;
      text-align: left;
      p {
        margin-bottom: 30px;
        font-size: 16px;
        font-weight: 800;
      }
      ul {
        padding: 0;
        font-size: 14px;
        li {
          display: block;
          line-height: 24px;
        }
      }
      .btn-group li {
        cursor: pointer;
      }
    }
    .qrcode {
      margin: 64px 40px 46px;
      text-align: center;
    }
  }
  .record-code {
    height: 32px;
    line-height: 32px;
    font-size: 12px;
  }
}
</style>