<!--
 * @Author: wyq
 * @Date: 2021-05-25 10:02:20
 * @LastEditTime: 2021-06-08 17:28:16
 * @LastEditors: wyq
 * @Description: 
 * @FilePath: \frontEnd\src\components\publicHeader.vue
-->
<template>
  <div class="public-header">
    <div class="header-content">
      <div class="header-logo">
        <img src="@/assets/images/logo.png" />
      </div>
      <ul class="header-menu">
        <li :class="routeName=='Index'?'active':''" @click="goIndex">{{$t('首页')}}</li>
        <li :class="routeName=='Search'?'active':''" @click="goSearch">{{$t('台企数据库')}}</li>
        <li class="special-new">
          <span>
            {{$t('专精特新')}}
            <i class="el-icon-arrow-down"></i>
          </span>
          <div class="special-new-hover">
            <div>
              <img class="icon-logo" src="@/assets/images/header/logoi.png" />
              <div>{{$t('银发友善食品')}}</div>
            </div>
            <p
              class="special-new-hover-text"
            >{{$t('银发友善食品(Eatender)是由中国台湾地区财团法人食品工业发展研究所的银发友善餐食研究团队评选出的对银发族最具友善性的体贴食品。现银发友善食品入选产品已累计达到282项，来自97家台湾公司。所有获选的银发友善食品都必须符合以下特征：使用中国台湾地区的在地农产食材、符合食品安全卫生规范；在中国台湾地区加工制造。')}}</p>
            <div class="special-new-hover-last" @click="goSearchLabel">
              <div>{{$t('进入银发友善食品')}}</div>
              <img src="@/assets/images/header/left.png" />
            </div>
          </div>
        </li>
      </ul>
      <div class="header-search">
        <el-input
          class="header-search-input"
          v-model="value"
          :placeholder="$t('请输入公司名称或唯一编号')"
          @keydown.enter.native="clickSearch"
          size="small"
        >
          <el-button slot="append" type="primary" icon="el-icon-search" @click="clickSearch"></el-button>
        </el-input>
      </div>
      <div class="header-end">
        <el-dropdown @command="handleCommand">
          <span class="el-dropdown-link">
            我的
            <i class="el-icon-arrow-down"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="collect">我的收藏</el-dropdown-item>
            <el-dropdown-item command="recording">{{$t('浏览记录')}}</el-dropdown-item>
            <el-dropdown-item command="signOut">退出</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <btn-i18n></btn-i18n>
      </div>
    </div>
  </div>
</template>
<script>
import btnI18n from './btnI18n'
export default {
  components: { btnI18n },
  props: {
    searchValue: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      value: '',
      routeName: '',
    }
  },
  watch: {
    // 初始化value
    searchValue(val) {
      if (val != this.value) {
        this.value = val
      }
    },
  },
  methods: {
    // 跳转首页
    goIndex() {
      this.checkRouter({
        path: '/index',
      })
    },
    goSearch() {
      this.checkRouter(
        {
          name: 'Search',
        },
        () => {
          this.$emit('resetForm')
        }
      )
    },
    // 跳转到搜索页
    goSearchLabel() {
      this.checkRouter(
        {
          path: '/search',
          query: {
            labelId: 1089,
          },
        },
        () => {
          this.$emit('resetForm')
          this.$emit('goSearchLabel')
        }
      )
    },
    // 点击搜索按钮
    clickSearch() {
      if (this.value.trim()) {
        this.checkRouter(
          {
            path: '/search',
            query: {
              name: this.value,
            },
          },
          () => {
            this.$emit('resetForm', 'name')
            this.$emit('clickSearch', this.value)
          }
        )
      } else {
        if (this.message) {
          this.message.close()
        }
        this.message = this.$message({
          message: this.$t('请输入公司名称或唯一编号'),
          type: 'warning',
        })
      }
    },
    // 选择我的下拉项
    handleCommand(val) {
      switch (val) {
        case 'collect':
          //我的收藏
          this.checkRouter({
            path: '/favorites',
          })
          break
        case 'recording':
          //浏览记录
          this.checkRouter({
            path: '/ecords',
          })
          break
        case 'signOut':
          // 退出
          localStorage.removeItem('username')
          this.$router.replace({
            path: '/login',
          })
          break
      }
    },
    // 校验路由是否支持跳转
    checkRouter(params, callback) {
      const that = this
      console.log(that.$route)
      if (
        that.$route.path !== params.path &&
        that.$route.name !== params.name &&
        that.$route.path !== params.path + '/'
      ) {
        that.$router.push(params)
      } else if (callback) {
        callback()
      }
    },
  },

  mounted() {
    this.routeName = this.$route.name
  },
}
</script>
<style lang="scss" scoped>
.public-header {
  position: sticky;
  top: 0;
  height: 64px;
  background: #ffffff;
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.15);
  z-index: 2001;
  .header {
    &-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 1200px;
      margin: 0 auto;
    }
    &-menu {
      height: 64px;
      padding: 0;
      margin: 0;
      li {
        display: inline-block;
        padding: 0 20px;
        height: 64px;
        line-height: 64px;
        color: #8590a6;
        font-size: 16px;
        cursor: pointer;

        &.active {
          color: #007aff;
        }
        &:hover {
          background-color: #eee;
        }
      }

      .special-new {
        position: relative;
      }
      .special-new:hover .el-icon-arrow-down {
        transform: rotate(180deg);
      }
      .special-new:hover .special-new-hover {
        display: block;
      }
      .special-new-hover {
        display: none;
        position: absolute;
        top: 50px;
        left: 10px;
        width: 168px;
        height: 379px;
        padding-top: 10px;
        // transition: all 0.6s;
        background-image: url('../assets/images/header/xiala_bg.png');
        background-repeat: no-repeat;
        z-index: 9999;
        box-sizing: border-box;
      }

      .special-new-hover > div:first-of-type {
        display: flex;
        flex-flow: row;
        justify-content: center;
        align-items: center;
        height: 40px;
        line-height: 40px;
        font-size: 16px;
        color: #333;
        .icon-logo {
          width: 24px;
          height: 24px;
          margin-right: 8px;
        }
      }

      .special-new-hover-text {
        display: flex;
        flex-flow: row;
        justify-content: center;
        align-items: center;
        margin: 0 12px;
        padding: 9px 0;
        border-top: 1px solid #eee;
        border-bottom: 1px solid #eee;
        letter-spacing: 1px;
        font-size: 12px;
        font-weight: 300;
        color: #666666;
        line-height: 18px;
      }

      .special-new-hover-last {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 53px;
        font-size: 16px;
        font-weight: 800;
        color: #333;
      }
    }
    &-search-input {
      width: 380px;
      ::v-deep .el-input-group__append {
        background-color: #3e68ff;
        color: #fff;
        border: 1px solid #3e68ff;
      }
    }
    &-end {
      .el-dropdown + .el-dropdown {
        margin-left: 40px;
      }
      .el-dropdown-link {
        color: #8590a6;
        font-size: 16px;
        cursor: pointer;
      }
    }
  }
}
</style>