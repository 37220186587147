<!--
 * @Author: wyq
 * @Date: 2021-05-31 14:17:03
 * @LastEditTime: 2021-06-03 17:20:34
 * @LastEditors: wyq
 * @Description: 
 * @FilePath: \frontEnd\src\components\backTop.vue
-->
<template>
  <el-backtop :bottom="100">
    <div class="left-top">
      <img src="@/assets/images/goTop.png" />
      <div>{{$t('置顶')}}</div>
    </div>
  </el-backtop>
</template>
<script>
export default {}
</script>
<style lang="scss" scoped>
.el-backtop {
  box-shadow: none;
}
.left-top {
  font-size: 0.75rem;
  width: 48px;
  color: #999999;
  background: #ffffff;
  text-align: center;
  font-size: 12px;
  font-weight: 800;
  color: #999999;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
}
</style>