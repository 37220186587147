/*
 * @Author: wyq
 * @Date: 2021-05-17 14:57:08
 * @LastEditTime: 2021-06-09 16:46:14
 * @LastEditors: wyq
 * @Description: 
 * @FilePath: \frontEnd\src\router\index.js
 */
import Vue from "vue";
import VueRouter from "vue-router";

const routes = [
	{
		path: '/',
		redirect: '/index'
	}, {
		path: "/login",
		name: "Login",
		component: () => import('../views/login/index.vue'),
	}, {
		path: "/index",
		name: "Index",
		component: () => import('../views/index/index.vue'),
	},
	// {
	// 	path: "/index-sub",
	// 	name: "indexSub",
	// 	component: () => import('../views/index/index-sub.vue'),
	// }, 
	{
		path: "/search",
		name: "Search",
		component: () => import('../views/search/index.vue'),
		// meta: {
		// 	keepAlive: true //需要被缓存的组件
		// },
	}, {
		path: "/details",
		name: "Details",
		component: () => import('../views/details/index.vue'),
	},
	{
		path: "/favorites",
		name: "Favorites",
		component: () => import('../views/mine/favorites.vue'),
	},
	{
		path: "/ecords",
		name: "Ecords",
		component: () => import('../views/mine/ecords.vue'),
	}
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
	scrollBehavior(to, from, saveTop) {
		if (saveTop) {
			return saveTop;
		} else {
			return { x: 0, y: 0 }
		}
	},
});

router.beforeEach((to, from, next) => {
	// 路由校验权限
	if (to.name != 'Login') {
		if (localStorage.getItem('username')) {
			next()
		} else {
			next({
				path: '/login'
			})
		}
	} else {
		next()
	}
})

Vue.use(VueRouter);

export default router;
