/*
 * @Author: wyq
 * @Date: 2021-06-03 09:41:36
 * @LastEditTime: 2021-06-24 14:36:47
 * @LastEditors: wyq
 * @Description:
 * @FilePath: \frontEnd\src\locale\zh-TW.js
 */
export default {
  // 首页
  "中国台湾地区行业手册": "中國臺灣地區行業手冊",
  "中国台湾地区行业手册的编订原则系参照联合国《国际行业分类标准》（International Standard Industrial Classification of All Economic Activites，简称ISIC）架构，并在其基础上将行业进一步划分为5级，总共囊括19大类、88中类、247小类、517细类、1598子类。分类中每一层级的所有类别都是非此即彼、相互排斥的。行业标准分类的归类原则建立在生产导向的概念上，即以产品、服务及生产要素投入、生产工艺及技术、产出特点及产出的用途等维度进行行业划分。在细类层级的划分上主要考量生产过程中的相似性，即将生产投入、生产程序及生产技术相似者划归为同一类别，有时也会考量产出的特性及用途；在中类、小类的分类层级中，生产程序及生产技术的重要性则相对较低": "中國臺灣地區行業手冊的編訂原則係參照聯合國《國際行業分類標準》（International Standard Industrial Classification of All Economic Activites，簡稱ISIC）架構，並在其基礎上將行業進一步劃分為5級，總共囊括19大類、88中類、247小類、517細類、1598子類。分類中每一層級的所有類別都是非此即彼、相互排斥的。行業標準分類的歸類原則建立在生産導嚮的概念上，即以産品、服務及生産要素投入、生産工藝及技術、産出特點及産出的用途等維度進行行業劃分。在細類層級的劃分上主要考量生産過程中的相似性，即將生産投入、生産程式及生産技術相似者劃歸為同一類別，有時也會考量産出的特性及用途；在中類、小類的分類層級中，生産程式及生産技術的重要性則相對較低",
  "行业分类": "行業分類",
  "农、林、渔、牧业": "農、林、漁、牧業",
  "矿业及土石采取业": "礦業及土石採取業",
  "制造业": "製造業",
  "电力及燃气供应业": "電力及燃氣供應業",
  "用水供应及污染整治业": "用水供應及汙染整治業",
  "营建工程业": "營建專案業",
  "批发及零售业": "批發及零售業",
  "运输及仓储业": "運輸及倉儲業",
  "住宿及餐饮业": "住宿及餐飲業",
  "出版、影音制作、传播及资通信服务业": "出版、影音製作、傳播及資通信服務業",
  "金融及保险业": "金融及保險業",
  "不动产业": "不動産業",
  "专业、科学及技术服务业": "專業、科學及技術服務業",
  "支持服务业": "支援服務業",
  "公共行政及国防&强制性社会安全": "公共行政及國防&強製性社會安全",
  "教育业": "教育業",
  "医疗保健及社会工作服务业": "醫療保健及社會工作服務業",
  "艺术、娱乐及休闲服务业": "藝術、娛樂及休閒服務業",
  "其他服务业": "其他服務業",
  // 头部组件
  "首页": "首頁",
  "台企数据库": "臺企數據庫",
  "专精特新": "專精特新",
  "银发友善食品": "銀發友善食品",
  "银发友善食品(Eatender)是由中国台湾地区财团法人食品工业发展研究所的银发友善餐食研究团队评选出的对银发族最具友善性的体贴食品。现银发友善食品入选产品已累计达到282项，来自97家台湾公司。所有获选的银发友善食品都必须符合以下特征：使用中国台湾地区的在地农产食材、符合食品安全卫生规范；在中国台湾地区加工制造。": "銀發友善食品(Eatender)是由中國臺灣地區財團法人食品工業發展研究所的銀發友善餐食研究團隊評選出的對銀發族最具友善性的體貼食品。現銀發友善食品入選産品已纍計達到282項，來自97家臺灣公司。所有獲選的銀發友善食品都必須符合以下特徵：使用中國臺灣地區的在地農産食材、符合食品安全衛生規範；在中國臺灣地區加工製造。",
  "进入银发友善食品": "進入銀發友善食品",
  "请输入公司名称或唯一编号": "請輸入公司名稱或唯一編號",
  "浏览记录": "瀏覽記錄",
  // body组件
  "请选择行业分类": "請選擇行業分類",
  // 尾部
  "版权归海峡经济研究院所有": "版權歸海峽經濟研究院所有",
  "备案号": "備案號",
  "闽ICP备2021008848号": "閩ICP備2021008848號",

  // 检索栏
  "全部结果": "全部結果",
  "地区": "地區",
  "资本规模": "資本規模",
  "标签": "標簽",
  "万": "萬",
  "亿": "億",
  "行业分类": "行業分類",
  "公司类型": "公司類型",
  "相关标签": "相關標簽",
  "上柜公司": "上櫃公司",
  "兴柜公司": "興櫃公司",
  "公开发行公司": "公開發行公司",
  "非公开发行": "非公開發行",
  // 搜索页
  "家企业": "家企業",
  "为您优先匹配前5000家企业": "為您優先匹配前5000家企業",
  "资本额降序": "資本額降序",
  "资本额升序": "資本額升序",
  "默认排序": "預設排序",

  // 详情
  "统一编号": "統一編號",
  "登记现状": "登記現狀",
  "电话": "電話",
  "电子邮件": "電子郵件",
  "资本总额(新台币)": "資本總額(新臺幣)",
  "公司官网": "公司官網",
  "营业项目": "營業項目",
  "产品信息": "産品信息",
  "暂时没有相关数据": "暫時冇有相關數據",

  "传真": "傳真",
  "代表人姓名": "代錶人姓名",
  "公司状况": "公司狀況",
  "核准建设日期": "核準建設日期",
  "实收资本(新台币)": "實收資本(新臺幣)",
  "公司简称": "公司簡稱",
  "股票代号": "股票代號",
  "董事长": "董事長",
  "总经理": "總經理",
  "发言人": "發言人",
  "产业类别": "産業類別",
  "主营业务": "主營業務",
  "投资联络": "投資聯絡",
  "联络人姓名": "聯絡人姓名",
  "联络人职称": "聯絡人職稱",
  "联络人电话": "聯絡人電話",
  "联络人邮箱": "聯絡人信箱",
  "奖补信息": "獎補信息",
  "获得由中国台湾地区": "獲得由中國臺灣地區",
  "颁发的": "頒發的",
  "获奖项目": "獲獎項目",
  "营收资讯": "營收資訊",
  "单位：新台币千元": "單位：新臺幣仟元",
  "本年至今累计营收": "本年至今纍計營收",
  "去年同期累计营收": "去年同期纍計營收",
  "去年同期月营收": "去年同期月營收",
  "累计营收成长率": "纍計營收成長率",
  "财务资讯": "財務資訊",
  "期别": "期別",
  "简明资产负债": "簡明資産負債",
  "简明综合损益表": "簡明綜合損益錶",
  "简明现金流量表": "簡明現金流量錶",
  "资产总计": "資産總計",
  "负债总计": "負債總計",
  "权益总计": "權益總計",
  "每股净值(元)": "每股淨值(元)",
  "营业收入": "營業收入",
  "营业利益": "營業利益",
  "税前净利": "稅前淨利",
  "基本每股盈余": "基本每股盈餘",
  "营业活动之净现金流入": "營業活動之淨現金流入",
  "投资活动之净现金流入": "投資活動之淨現金流入",
  "筹资活动之净现金流入": "籌資活動之淨現金流入",

  // 产品
  "产品名称": "産品名稱",
  "包装": "包裝",
  "储藏方式": "儲藏方式",
  "产品类别": "産品類別",
  "获奖内容": "獲獎內容",
  "参考售价": "參考售價",
  "销售通路": "銷售通路",
  "产品介绍": "産品介紹",

  // 收藏
  "添加文件夹": "添加檔案夾",
  "编辑": "編輯",
  "删除": "刪除",
  "请输入内容": "請輸入內容",

  // 浏览历史
  "为您找到": "為您找到",
  "条相关数据": "條相關數據",

  // 登录页
  "欢迎，请登录": "歡迎，請登入",
  "请输入账户": "請輸入賬戶",
  "请输入密码": "請輸入密碼",
  "登录": "登入",

  "置顶": "置頂",

  "添加收藏夹": "添加收藏夾",
  "收藏夹名称": "收藏夾名稱",
  "请输入名称": "請輸入名稱",
  "确认添加": "確認添加",
  "失败": "失敗",
  "收藏夹名称不能为空": "收藏夾名稱不能為空",
  "收藏夹编辑": "收藏夾編輯",
  "确认修改": "確認修改",
  "删除确认": "刪除確認",
  "确认删除该分组吗": "確認刪除該分組嗎",
  "分组": "分組",
  "创建": "創建",
  "确认": "確認",
  "创建成功": "創建成功"
}