/*
 * @Author: wyq
 * @Date: 2021-05-25 15:33:17
 * @LastEditTime: 2021-06-16 18:04:04
 * @LastEditors: wyq
 * @Description:
 * @FilePath: \frontEnd\src\utils\filters.js
 */
function formatNumber(num) {
  if (num !== '') {
    var result = '',
      counter = 0
    num = (num || 0).toString()
    for (var i = num.length - 1; i >= 0; i--) {
      counter++
      result = num.charAt(i) + result
      if (!(counter % 3) && i != 0) {
        result = ',' + result
      }
    }
    return result
  } else {
    return '-'
  }
}
export {
  formatNumber
}