<!--
 * @Author: wyq
 * @Date: 2021-05-17 14:57:08
 * @LastEditTime: 2021-06-09 14:47:28
 * @LastEditors: wyq
 * @Description: 
 * @FilePath: \frontEnd\src\App.vue
-->
<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
body {
  margin: 0px;
  padding: 0px;
  min-width: 1280px;
  height: 100vh;
  background: #f4f6f8;
}

#app {
  margin: 0px;
  padding: 0px;
}
.page_bd {
  width: 1200px;
  margin: 20px auto;
  min-height: calc(100vh - 136px);
}
p {
  margin: 0rem;
  padding: 0rem;
}
.el-button.el-button--primary {
  background-color: #3e68ff;
  border-color: #3e68ff;
}
.el-button.el-button--warning {
  background-color: #f89941;
  border-color: #f89941;
}
::v-deep .el-pagination .el-pager li.active {
  background-color: #3e68ff;
}

.company-tag {
  margin-left: 4px;
  margin-right: 16px;
  height: 22px;
  line-height: 22px;
  font-size: 0.875rem;
  padding: 0px 0.4375rem;
  font-weight: 800;
  // 银发
  &_1089 {
    background: #fdf4e5;
    border: 1px solid #f39801;
    color: #f39801;
  }
  // 磐石
  &_1090 {
    background: #fee5ec;
    border: 1px solid #e6104a;
    color: #e6104a;
  }
  // 研究
  &_1091 {
    background: rgba(6, 143, 121, 0.1);
    border: 1px solid #068f79;
    color: #068f79;
  }
  // 小巨人
  &_1092 {
    background: rgba(97, 119, 196, 0.1);
    border: 1px solid #6177c4;
    color: #6177c4;
  }
  // 事业
  &_1093 {
    background: rgba(112, 40, 205, 0.1);
    border: 1px solid #7028cd;
    color: #7028cd;
  }
}
</style>
