/*
 * @Author: wyq
 * @Date: 2021-05-18 10:17:34
 * @LastEditTime: 2021-06-07 15:11:54
 * @LastEditors: wyq
 * @Description: 
 * @FilePath: \frontEnd\src\http\service.js
 */
import http from './axios';

const baseUrl = '/v1'
// 登录
function login(params) {
  return http.post(`${baseUrl}/user/sign_in`, params)
}
// 获取行业树
function getIndustryTree(params) {
  return http.get(`${baseUrl}/company/get_industry_type_tree`, { params })
}
// 获取地区下拉选项
function getAreaList(params) {
  return http.get(`${baseUrl}/company/search_region`, { params })
}
// 获取行业下拉选项
function getIndustryList(params) {
  return http.get(`${baseUrl}/company/search_industry_type`, { params })
}
// 获取公司类型列表
function getCompanyTypeList() {
  return http.get(`${baseUrl}/company/get_company_type_list`)
}
// 获取标签列表
function getRelatedTagList() {
  return http.get(`${baseUrl}/company/search_related_labels`)
}
// 按条件搜索
function getCompanyList(params) {
  return http.post(`${baseUrl}/company/search`, params)
}

// 获取用户分组
function getGroupList(params) {
  return http.get(`${baseUrl}/user/group`, { params })
}
// 新建分组
function postCreatGroup(params) {
  return http.post(`${baseUrl}/user/group`, params)
}
// 删除分组
function deleteGroup(params) {
  return http.delete(`${baseUrl}/user/group`, { params })
}
// 编辑分组
function editGroup(params) {
  return http.patch(`${baseUrl}/user/group`, params)
}
// 收藏管理
function postCollectionManagement(params) {
  return http.post(`${baseUrl}/user/collection_management`, params)
}
// 获取浏览历史
function getBrowseRecords(params) {
  return http.post(`${baseUrl}/user/browse_records`, params)
}
// 获取用户收藏数据
function getGroupCompanyList(params) {
  return http.post(`${baseUrl}/user/group_company_list`, params)
}
// 获取公司详情
function getCompanyDetails(params) {
  return http.get(`${baseUrl}/company/company_details`, { params })
}
export default {
  login,
  getIndustryTree,
  getAreaList,
  getIndustryList,
  getCompanyTypeList,
  getRelatedTagList,
  getCompanyList,
  getGroupList,
  postCreatGroup,
  deleteGroup,
  editGroup,
  postCollectionManagement,
  getBrowseRecords,
  getGroupCompanyList,
  getCompanyDetails
}

